import React, { useState,useEffect } from "react";
import { useNavigate, Link, useParams, } from "react-router-dom";
import axios from "../api/baseurl";
import { toast} from "react-toastify";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

const AddEmployees = ({ isOpen, onRequestClose, companycode}) => {
  const navigate = useNavigate();

  const url = "/api/CreateCompanyandLab";
  console.log("cc",companycode);
  const [employeeDetails, setEmployeeDetails] = useState(
    {
     username:"",
     role:"ROLE_EMPLOYEE",
     employeeDetails:{
        name:"",
        lastname:"",
        phoneno:"",
        gender:"",
        DOB:"",
        aadhaarnumber:"",
        companycode:companycode,
        email:"",
        address:"",
        pan:"",
        image:null,
        imageMeta:"",
        empid:"",
        designation:"",
        dateOfJoining:"",
        packagetype:"",
        sendNotification:false
     }
      },
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
        const emailCheckResponse = await axios.get(`/api/accounts/getByEmail=${employeeDetails.email}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (emailCheckResponse.data) {
          toast.error("Email already exist.");
          setLoading(false);
          return;
        }
      } catch (error) {
        handleError(error);
        setLoading(false);
        return;
      }
      axios
        .post(url, {
          username: employeeDetails.email,
          role:"ROLE_EMPLOYEE",
          employeeDetails: {
            name:employeeDetails.name,
            lastname:employeeDetails.lastname,
            phoneno:employeeDetails.phoneno,
            gender:employeeDetails.gender,
            DOB:employeeDetails.DOB,
            aadhaarnumber:employeeDetails.aadhaarnumber,
            companycode:companycode,
            email:employeeDetails.email,
            address:employeeDetails.address,
            pan:employeeDetails.pan,
            image:employeeDetails.image,
            imageMeta:employeeDetails.imageMeta,
            empid:employeeDetails.empid,
            designation:employeeDetails.designation,
            dateOfJoining:employeeDetails.dateOfJoining,
            packagetype:employeeDetails.packagetype,
            sendNotification:employeeDetails.sendNotification
          }
        }, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          setLoading(false);
          const regiteredusername = {
            username: employeeDetails.username,
            orgcode: employeeDetails.companycode,
          };
          localStorage.setItem(
            "RegistrationData",
            JSON.stringify(regiteredusername)
          );
         
          onRequestClose();
          setEmployeeDetails("");
          toast("Employee account created successfully and Password sent to their email");
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
          setEmployeeDetails("");
        });
  
  }

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


   
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

 const handleImageChange=async(event)=>{
   const convertedImage= await getBase64FromFile(event.target.files[0]);
   const imageObject = {...employeeDetails,
         imageMeta: convertedImage.metadata,
         image:convertedImage.content,};
         setEmployeeDetails(imageObject);
 }

  function rhandle(e) {
    const { id, value } = e.target;
    setEmployeeDetails((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  const [designations, setDesignations] = useState([]);
  const [gender, setGender] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const des = await axios.get(`/api/designation/companycode=${companycode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDesignations(des.data);
        const gender = await axios.get(`/api/gender`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGender(gender.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch designations.");
      }
    };
    fetchData();
  }, []);

  const handleSendNotificationChange = (e) => {
    const { checked } = e.target;
    setEmployeeDetails((prevState) => ({
      ...prevState,
      sendNotification: checked
    }));
  };


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
    <Modal.Title>Create Employee Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {loading && <div className="loading"></div>} 
    <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-2">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column align-items-center">
              <div className="mb-4">
                {employeeDetails.image ? (
                  <img
                  className="img-fluid mb-1"
                  id="image"
                  src={`${employeeDetails?.imageMeta},${employeeDetails?.image}`}
                  alt="User Profile"
                  style={{
                    width: "150px",
                    height: "150px",
                    display: "block",
                    background: "#ccc",
                    marginTop: "20px",
                    borderRadius: "50%"
                  }}
                />
              ) : (
                <div
                  className="img-fluid mb-1"
                  id="image"
                  style={{
                    width: "150px",
                    height: "150px",
                    display: "block",
                    background: "#ccc",
                    marginTop: "20px",
                    borderRadius: "50%"
                  }}
                ></div>
              )}
              <input
                className="col-7"
                type="file"
                id="image"
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  handleImageChange(event);
                }}
                accept="image/*"
              />
            </div>
            </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="name">First Name <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      placeholder="First Name"
                      value={employeeDetails.name}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                   </div>
                  </div>
               
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="name">Last Name</label>
                    <input
                      className="form-control"
                      id="lastname"
                      type="text"
                      placeholder="Last Name"
                      value={employeeDetails.lastname}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
               </div>
               </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="email">Email Id <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      placeholder="Email"
                      value={employeeDetails.email}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="contact">Phone Number <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="phoneno"
                      type="text"
                      placeholder="Phone Number"
                      value={employeeDetails.phoneno}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                <div className="form-floating-sm mb-2">
                    <label htmlFor="gender">
                      Gender <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="gender"
                      value={employeeDetails.gender}
                      onChange={(e) => rhandle(e)}
                      required
                    >
                      <option value="">Select Gender</option>
                      {gender.map((cat) => (
                        <option key={cat.id} value={cat.gender}>
                          {cat.gender}
                        </option>
                      ))}
                    </select>
                    </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="DOB">DOB <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="DOB"
                      type="date"
                      placeholder="DOB"
                      value={employeeDetails.DOB}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pan">PAN</label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      placeholder="PAN"
                      value={employeeDetails.pan}
                      onChange={(e) => rhandle(e)}
                      // required
                    />
                    
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="aadhaarnumber">Aadhar Number</label>
                    <input
                      className="form-control"
                      id="aadhaarnumber"
                      type="text"
                      placeholder="Aadhar Number"
                      value={employeeDetails.aadhaarnumber}
                      onChange={(e) => rhandle(e)}
                      // required
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="empid">Employee Id <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="empid"
                      type="text"
                      placeholder="Employee Id"
                      value={employeeDetails.empid}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                    
                  </div>
                </div>

                <div className="col-md-6">
                <div className="form-floating-sm mb-2">
                    <label htmlFor="designation">
                      Designation <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="designation"
                      value={employeeDetails.designation}
                      onChange={(e) => rhandle(e)}
                      required
                    >
                      <option value="">Select Designation</option>
                      {designations.map((cat) => (
                        <option key={cat.id} value={cat.designation}>
                          {cat.designation}
                        </option>
                      ))}
                    </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="dateOfJoining">Date of Joining <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="dateOfJoining"
                      type="date"
                      placeholder="Date Of Joining"
                      value={employeeDetails.dateOfJoining}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>
            
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="address">Address</label>
                    <textarea
                      className="form-control"
                      id="address"
                      type="text"
                      placeholder="Address"
                      value={employeeDetails.address}
                      onChange={(e) => rhandle(e)}
                      rows="2"
                      // required
                    />
                 
                </div>
                </div>

                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sendNotification"
                    checked={employeeDetails.sendNotification}
                    onChange={handleSendNotificationChange}
                  />
                  <label className="form-check-label" htmlFor="sendNotification">
                    Send Notification
                  </label>
                  </div>
              
                <br/>

                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>
              </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
}
export default AddEmployees;
